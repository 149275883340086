import React, { useContext } from "react";

import Modal from "../shared/Modal";
import CartContext from "../../store/cart-context";
import { Link, useNavigate } from "react-router-dom";
import Card from "../shared/Card";
import CartItem from "./CartItem";

import classes from "./Cart.module.css";

const Cart = (props) => {
  const cartCtx = useContext(CartContext);
  const totalAmount = cartCtx.totalAmount;
  const hasItems = cartCtx.items.length > 0;
  const navigate = useNavigate();

  const handleRemoveItem = (id) => {
    cartCtx.removeItem(id);
  };
  const handleAddItem = (item) => {
    cartCtx.addItem({ ...item, amount: 1 });
  };

  // handleRemoveItem.bind(null, item.id)
  // handleAddItem.bind(null, item)

  const handleViewCart = () => {
    props.onHideCart();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckout = () => {
    props.onHideCart();
    navigate("/checkout");
  };

  const cartItems = (
    <ul className={classes["cart-items"]}>
      {cartCtx.items.map((item, index) => (
        <CartItem
          key={index}
          item={item}
          onRemove={() => handleRemoveItem(item.id)}
          onAdd={() => handleAddItem(item)}
        />
      ))}
    </ul>
  );
  return (
    <Modal onHideCart={props.onHideCart}>
      {hasItems ? (
        <Card className={classes["cart-card"]}>
          {cartItems}
          <div className={classes.total}>
            <span>Total Amount</span>
            <span>${totalAmount}</span>
          </div>
          <div className={classes.actions}>
            <button
              className={classes["button-close"]}
              onClick={props.onHideCart}
            >
              Close
            </button>
            <Link onClick={handleViewCart} to="/cart">
              View My Cart
            </Link>
            <button className={classes.button} onClick={handleCheckout}>
              Checkout
            </button>
          </div>
        </Card>
      ) : (
        <div className={classes["no-items"]}>
          <span>Your Cart is empty</span>
          <Link to="/products" onClick={props.onHideCart}>
            Continue Shopping
          </Link>
        </div>
      )}
    </Modal>
  );
};

export default Cart;
