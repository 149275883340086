import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logoImg from "../../assets/logo.png";
import NavLinks from "./NavLinks";
import SideMenu from "./SideMenu";
import Backdrop from "../shared/Backdrop";
import HeaderCartButton from "./HeaderCartButton";

import classes from "./Header.module.css";

const Header = (props) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [scrollUp, setScrollUp] = useState(false);

  const openMenuHandler = () => {
    setSideMenuIsOpen(true);
  };

  const closeMenuHandler = () => {
    setSideMenuIsOpen(false);
  };
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (
        currentScrollY > lastScrollY ||
        window.scrollY <= (20 * window.innerHeight) / 100
      ) {
        setScrollUp(false);
      } else if (
        currentScrollY < lastScrollY &&
        window.scrollY >= (90 * window.innerHeight) / 100
      ) {
        setScrollUp(true);
      }

      lastScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sideMenuHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    closeMenuHandler();
  };

  const headerClasses = scrollUp
    ? `${classes.header} ${classes.sticky}`
    : classes.header;
  return (
    <header className={headerClasses}>
      {sideMenuIsOpen && <Backdrop onClick={closeMenuHandler} />}
      {sideMenuIsOpen && (
        <SideMenu>
          <div className={classes.sideMenuWrapper}>
            <div className={classes.sideMenuLogo}>
              <Link to="/">
                <img
                  src={logoImg}
                  alt="ImagineIt Chee"
                  className={classes.sideMenuLogoImg}
                  onClick={sideMenuHandler}
                />
              </Link>
            </div>
            <nav className={classes.sideMenuNav}>
              <NavLinks sideMenu onClick={closeMenuHandler} />
            </nav>
            <div className={classes.sideMenuFooter}>
              <h4>
                Powered by BuyPits<sup>TM</sup>
              </h4>
            </div>
          </div>
        </SideMenu>
      )}
      <div className={classes.headerWrapper}>
        <button className={classes.sideMenuBtn} onClick={openMenuHandler}>
          <span />
          <span />
          <span />
        </button>
        <nav className={classes.navLeft}>
          <NavLinks />
        </nav>
        <div className={classes.navLogo}>
          <Link to="/">
            <img
              src={logoImg}
              alt="ImagineIt Chee"
              className={classes.navLogoImg}
            />
          </Link>
        </div>
        <HeaderCartButton onShowCart={props.onShowCart} />
      </div>
    </header>
  );
};

export default Header;
