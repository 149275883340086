import React from "react";
import { Link } from "react-router-dom";

import classes from "./FeaturedCategories.module.css";

const DUMMY_CAT = [
  {
    title: "wellness",
    desc: "Your Wellness Journey with Every Choice",
  },
  {
    title: "living",
    desc: "Your Living Space with Smart Innovations and Eco-friendly Elegance",
  },
  {
    title: "electronics",
    desc: "Your Digital Lifestyle with the Latest Gadgets and Accessories",
  },
  {
    title: "fashion",
    desc: "Sustainable Style, Unparalleled Grace",
  },
];

const FeaturedCategories = () => {
  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.categories}>
      <div className={classes.wrapper}>
        {DUMMY_CAT.map((category, index) => (
          <div
            className={`${classes.category} ${classes[category.title]}`}
            key={index}
          >
            <div className={classes.content}>
              <h3>{category.title}</h3>
              <p>{category.desc}</p>
              <div className={classes.explore}>
                <Link
                  to={`/collections/${category.title}`}
                  onClick={scrollToTop}
                >
                  Explore
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.smallScreen}>
        {DUMMY_CAT.map((cat, index) => (
          <div key={index} className={classes.smCategory}>
            <Link
              to={`/collections/${cat.title}`}
              onClick={scrollToTop}
              className={`${classes.catImgBox} ${classes[cat.title]}`}
            ></Link>
            <h5>{cat.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCategories;
