import React from "react";

import classes from "./LoadingSpinner.module.css";

const LoadingSpinner = (props) => {
  return (
    <div
      className={`${props.asOverlay && classes.overlay} ${
        classes.centerSpinner
      }`}
    >
      <div className={classes.doubleRing}></div>
    </div>
  );
};

export default LoadingSpinner;
