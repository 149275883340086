import React from "react";

import classes from "./ShowOrders.module.css";

const ShowOrders = ({ orders }) => {
  return (
    <div className={classes.showOrders}>
      <ul className={classes.orders}>
        {orders.map((order, index) => (
          <li key={index} className={classes.orderList}>
            <div className={classes.orderContent}>
              <h4>Invoice # {index + 1}</h4>
              <div className={classes.customerDetails}>
                <p>
                  Name:{" "}
                  <span>
                    {order.fname} {order.lname}
                  </span>
                </p>
                <p>
                  Email: <span>{order.email}</span>
                </p>
                <p>
                  Address: <span>{order.address}</span>
                </p>
                <p>
                  Address Opt: <span>{order.addressOptional}</span>
                </p>
                <p>
                  Contact Number: <span>{order.phoneNumber}</span>
                </p>
                <p>
                  City: <span>{order.city}</span>
                </p>
                <p>
                  Country: <span>{order.country}</span>
                </p>
              </div>

              <div className={classes.customerOrder}>
                <ul className={classes.cartItems}>
                  {order.cart.items.map((item, i) => (
                    <li key={i}>
                      <div className={classes.orderItem}>
                        <div className={classes.itemImage}>
                          <img src={item.imageLink} alt="order" />
                        </div>
                        <div className={classes.itemTitleAndId}>
                          <h5>{item.title.slice(0, 30)}</h5>
                          <h5>{item.id}</h5>
                        </div>
                        <h5>x {item.amount}</h5>
                        <p>{item.price}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className={classes.totalAmount}>
                <h3>
                  <span>Grand Total: </span>
                  {order.cart.totalAmount}
                </h3>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShowOrders;
