import React, { useContext, useState, useEffect } from "react";
import { BiCart } from "react-icons/bi";
import { MdOutlineDiscount } from "react-icons/md";

import CartContext from "../../store/cart-context";
import classes from "./HeaderCartButton.module.css";
import { Link } from "react-router-dom";

const HeaderCartButton = (props) => {
  const [btnIsActive, setBtnIsActive] = useState(false);
  const cartCtx = useContext(CartContext);
  const { items } = cartCtx;

  const totalCartItems = items.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);

  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    setBtnIsActive(true);

    const timer = setTimeout(() => {
      setBtnIsActive(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [items]);

  const cartBtnClasses = `${classes.navCart} ${classes.navIcon} ${
    btnIsActive && classes.bump
  }`;
  return (
    <div className={classes.navRight}>
      <div className={cartBtnClasses} onClick={props.onShowCart}>
        <BiCart />
        <span>{totalCartItems}</span>
      </div>
      <Link className={`${classes.navDiscount} ${classes.navIcon}`} to="/sale">
        <MdOutlineDiscount />
      </Link>
    </div>
  );
};

export default HeaderCartButton;
