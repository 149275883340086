import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavLinks.module.css";

const NavLinks = (props) => {
  const scrollToTop = () => {
    // closing the sideMenu first
    props.sideMenu && props.onClick();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <ul className={classes.navList}>
      <li>
        <NavLink
          to="/"
          style={({ isActive }) => ({ color: isActive && "#3f72af" })}
          onClick={scrollToTop}
        >
          HOME
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/products"
          style={({ isActive }) => ({ color: isActive && "#3f72af" })}
          onClick={scrollToTop}
        >
          PRODUCTS
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/collections"
          style={({ isActive }) => ({ color: isActive && "#3f72af" })}
          onClick={scrollToTop}
        >
          COLLECTION
        </NavLink>
      </li>
      {props.sideMenu && (
        <li>
          <NavLink
            to="/sale"
            style={({ isActive }) => ({ color: isActive && "#3f72af" })}
            onClick={scrollToTop}
          >
            SHOP DEALS
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
