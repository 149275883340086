import React from "react";

import classes from "./CustomInput.module.css";

const CustomInput = ({
  type,
  label,
  productData,
  setProductData,
  notReq,
  textArea,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };
  return (
    <div className={`${classes.input} ${classes[label]}`}>
      <label htmlFor={label}>
        {label}
        {!notReq && <sup>*</sup>}
      </label>
      {textArea ? (
        <textarea
          name="desc"
          rows="12"
          cols="33"
          value={productData.desc}
          onChange={handleChange}
          placeholder="minimum 300 Characters are required"
          required
        />
      ) : (
        <input
          type={type}
          name={label}
          value={productData[label]}
          onChange={handleChange}
          required={notReq ? false : true}
        />
      )}
    </div>
  );
};

export default CustomInput;
