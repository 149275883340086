import React from "react";

import classes from "./ReviewInput.module.css";

const ReviewInput = ({ label, type, reviewData, setReviewData, notReq }) => {
  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviewData({ ...reviewData, [name]: value });
  };
  return (
    <div className={classes.reviewInput}>
      <label htmlFor={label}>
        {label}
        {!notReq && <sup>*</sup>}
      </label>
      <input
        type={type}
        value={reviewData[label]}
        name={label}
        onChange={handleReviewChange}
        placeholder={
          label === "country" &&
          "format -- USA, Germany, Australia, UAE, Canada"
        }
      />
    </div>
  );
};

export default ReviewInput;
