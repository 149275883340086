import React from "react";
import classes from "./Banner.module.css";
import WarrantyIcon from "./WarrantyIcon";
import FreeShipping from "./FreeShipping";
import ShipNextDay from "./ShipNextDay";

const Banner = () => {
  return (
    <div className={classes.banner}>
      <div className={classes.element}>
        <div className={classes.icon}>
          <WarrantyIcon />
        </div>
        <div className={classes.content}>
          <h3>Warranty</h3>
          <p>All our items come with 3 month assurance</p>
        </div>
      </div>
      <div className={classes.element}>
        <div className={classes.icon}>
          <ShipNextDay />
        </div>
        <div className={classes.content}>
          <h3>Ships Next Day</h3>
          <p>We ship all orders on the following business day</p>
        </div>
      </div>
      <div className={classes.element}>
        <div className={classes.icon}>
          <FreeShipping />
        </div>
        <div className={classes.content}>
          <h3>Free Shipping</h3>
          <p>Savor the benefit of free shipping worldwide</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
