import React from "react";

import classes from "./UpdateAndDelete.module.css";
import ItemList from "./ItemList";

const UpdateAndDelete = ({ products }) => {
  return (
    <div className={classes.updateAndDelete}>
      <ul>
        {products.map((item, index) => (
          <ItemList key={index} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default UpdateAndDelete;
