import React from "react";

const FreeShipping = () => {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="2rem"
      viewBox="0 0 612 612"
    >
      <g>
        <g>
          <path
            d="M612,338.19v55.625c0,13.877-11.249,25.125-25.126,25.125h-26.885c-4.857-33.332-33.585-58.961-68.259-58.961
			c-34.59,0-63.317,25.629-68.175,58.961H195.162c-4.857-33.332-33.585-58.961-68.175-58.961s-63.318,25.629-68.175,58.961H25.143
			c-13.876,0-25.125-11.248-25.125-25.125V338.19H612z M173.067,203.958h-14.313l-5.259,27.155h13.945
			c9.292,0,16.996-6.363,16.996-17.004C184.436,207.631,180.159,203.958,173.067,203.958z M543.892,428.907
			c0,28.729-23.367,52.094-52.095,52.094c-28.811,0-52.178-23.367-52.178-52.094c0-28.811,23.367-52.178,52.178-52.178
			C520.524,376.729,543.892,400.097,543.892,428.907z M517.845,428.907c0-14.404-11.726-26.047-26.047-26.047
			c-14.405,0-26.048,11.643-26.048,26.047c0,14.322,11.643,26.049,26.048,26.049C506.119,454.956,517.845,443.229,517.845,428.907z
			 M179.063,428.907c0,28.729-23.283,52.094-52.094,52.094s-52.094-23.367-52.094-52.094c0-28.811,23.284-52.178,52.094-52.178
			S179.063,400.097,179.063,428.907z M153.017,428.907c0-14.404-11.642-26.047-26.046-26.047c-14.406,0-26.047,11.643-26.047,26.047
			c0,14.322,11.642,26.049,26.047,26.049C141.375,454.956,153.017,443.229,153.017,428.907z M611.982,324.302H0V156.125
			c0-13.903,11.307-25.125,25.125-25.125h379.736c13.903,0,25.126,11.223,25.126,25.125v18.678h49.833
			c8.794,0,17.253,3.518,23.534,9.715l98.745,97.656c6.281,6.282,9.883,14.824,9.883,23.786V324.302z M89.184,228.545l4.646-24.586
			h34.245l2.568-13.577H80.866l-16.881,86.837H79.64l6.854-35.104h28.863l2.568-13.569H89.184z M180.772,240.649v-0.246
			c13.086-4.278,19.81-17.118,19.81-28.986c0-8.923-4.4-15.655-11.745-18.837c-3.541-1.586-7.828-2.2-12.473-2.2h-30.696
			l-16.874,86.836h15.769l6.241-32.413h15.655l11.009,32.413h16.874l-11.614-32.168
			C181.623,241.876,180.772,240.649,180.772,240.649z M277.113,190.381h-51.854l-16.996,86.837h53.932l2.568-13.577h-38.155
			l4.523-23.359h29.231l2.691-13.577h-29.231l4.4-22.746h36.201L277.113,190.381z M347.174,190.381h-51.855l-16.996,86.837h53.933
			l2.567-13.577h-38.155l4.523-23.359h29.231l2.691-13.577h-29.232l4.401-22.746h36.2L347.174,190.381z M561.731,278.655
			l-77.305-73.787c-0.754-0.669-1.759-1.088-2.764-1.088h-18.174c-2.178,0-3.937,1.759-3.937,3.937v73.787
			c0,2.178,1.759,3.936,3.937,3.936h95.562C562.568,285.439,564.327,281.084,561.731,278.655z"
          />
        </g>
      </g>
    </svg>
  );
};

export default FreeShipping;
