import React, { useReducer, useEffect } from "react";
import CartContext from "./cart-context";

const getCartData = () => {
  let storedCartData = localStorage.getItem("cartState");
  if (!storedCartData) {
    return {
      items: [],
      totalAmount: "0",
    };
  } else {
    return JSON.parse(storedCartData);
  }
};

// const defaultCartState = {
//   items: [],
//   totalAmount: 0,
// };
const defaultCartState = getCartData();

const cartReducer = (state, action) => {
  if (action.type === "ADD") {
    const itemTotalPrice = action.item.price * action.item.amount;
    let updatedAmount =
      parseFloat(state.totalAmount) + parseFloat(itemTotalPrice);
    updatedAmount = updatedAmount.toFixed(2);
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.item.id
    );
    const existingCartItem = state.items[existingCartItemIndex];
    let updatedItems;
    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        amount: existingCartItem.amount + action.item.amount,
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems = state.items.concat(action.item);
    }

    return { items: updatedItems, totalAmount: updatedAmount };
  }
  if (action.type === "REMOVE") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.id
    );

    const existingCartItem = state.items[existingCartItemIndex];
    let updatedAmount =
      parseFloat(state.totalAmount) - parseFloat(existingCartItem.price);
    updatedAmount = updatedAmount.toFixed(2);
    let updatedItems;
    if (existingCartItem.amount === 1) {
      updatedItems = state.items.filter((item) => item.id !== action.id);
    } else {
      const updatedItem = {
        ...existingCartItem,
        amount: existingCartItem.amount - 1,
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    }
    return { items: updatedItems, totalAmount: updatedAmount };
  }
  return defaultCartState;
};

const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );

  // Add Data into localStorage
  useEffect(() => {
    localStorage.setItem("cartState", JSON.stringify(cartState));
  }, [cartState]);

  const addItemToCartHandler = (item) => {
    dispatchCartAction({ type: "ADD", item: item });
  };
  const removeItemFromCart = (id) => {
    dispatchCartAction({ type: "REMOVE", id: id });
  };

  const cartContext = {
    items: cartState.items,
    totalAmount: cartState.totalAmount,
    addItem: addItemToCartHandler,
    removeItem: removeItemFromCart,
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
