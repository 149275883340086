import React from "react";
import ManageOutlet from "../components/manageProducts/ManageOutlet";

const ProductsManagement = () => {
  return (
    <>
      <ManageOutlet />
    </>
  );
};

export default ProductsManagement;
