import React, { useState } from "react";

import CustomInput from "./CustomInput";
import classes from "./CreateProduct.module.css";
import ReviewInput from "./ReviewInput";

const CreateProduct = () => {
  const [productData, setProductData] = useState({
    title: "",
    desc: "",
    imageLink: "",
    imageLinkPlus: "",
    hoverLink: "",
    imageLinkExtra: "",
    category: "",
    price: "",
    oldPrice: "",
    rating: "",
    reviews: [],
    productLink: "",
  });
  const [reviewData, setReviewData] = useState({
    personName: "",
    imageLink: "",
    country: "",
    rating: "",
    reviewText: "",
  });

  const addReview = () => {
    if (
      reviewData.personName.trim() &&
      reviewData.country.trim() &&
      reviewData.rating.trim() &&
      reviewData.reviewText.trim()
    ) {
      setProductData({
        ...productData,
        reviews: [...productData.reviews, reviewData],
      });
      setReviewData({
        imageLink: "",
        personName: "",
        country: "",
        rating: "",
        reviewText: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your logic to send the data to the server (REST API) here
    // console.log(productData);
    const postNewProduct = async () => {
      try {
        const res = await fetch(
          "https://backend.buypits.com/products/add-new-product",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(productData),
          }
        );

        if (res.status === 200) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          alert("New Product is Added");
        }
      } catch (error) {
        console.log(error);
      }
    };
    postNewProduct();

    setProductData({
      title: "",
      desc: "",
      imageLink: "",
      imageLinkPlus: "",
      hoverLink: "",
      imageLinkExtra: "",
      category: "",
      price: "",
      oldPrice: "",
      rating: "",
      reviews: [],
      productLink: "",
    });
  };
  return (
    <div className={classes.createProduct}>
      <form onSubmit={handleSubmit}>
        <CustomInput
          type="text"
          label="title"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="desc"
          productData={productData}
          setProductData={setProductData}
          textArea
        />

        <CustomInput
          type="text"
          label="imageLink"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="imageLinkPlus"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="hoverLink"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="imageLinkExtra"
          productData={productData}
          setProductData={setProductData}
          notReq
        />
        <CustomInput
          type="text"
          label="category"
          productData={productData}
          setProductData={setProductData}
        />

        <CustomInput
          type="text"
          label="price"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="oldPrice"
          productData={productData}
          setProductData={setProductData}
          notReq
        />
        <CustomInput
          type="text"
          label="rating"
          productData={productData}
          setProductData={setProductData}
        />
        <CustomInput
          type="text"
          label="productLink"
          productData={productData}
          setProductData={setProductData}
          notReq
        />
        <div className={classes.reviews}>
          <h3>Review Section</h3>
          <ReviewInput
            type="text"
            label="personName"
            reviewData={reviewData}
            setReviewData={setReviewData}
          />
          <ReviewInput
            type="text"
            label="imageLink"
            reviewData={reviewData}
            setReviewData={setReviewData}
            notReq
          />
          <ReviewInput
            type="text"
            label="country"
            reviewData={reviewData}
            setReviewData={setReviewData}
          />
          <ReviewInput
            type="text"
            label="rating"
            reviewData={reviewData}
            setReviewData={setReviewData}
          />
          <ReviewInput
            type="text"
            label="reviewText"
            reviewData={reviewData}
            setReviewData={setReviewData}
          />
          {productData.reviews.length ? (
            <p>Total Reviews Added: {productData.reviews.length}</p>
          ) : (
            ""
          )}
          <button type="button" onClick={addReview}>
            Add Review
          </button>
        </div>

        <button type="submit" className={classes.submitBtn}>
          Create Item
        </button>
      </form>
    </div>
  );
};

export default CreateProduct;
