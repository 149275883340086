import React from "react";
import { MdEdit, MdDelete } from "react-icons/md";

import classes from "./ItemList.module.css";

const ItemList = ({ item }) => {
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:5001/products/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        console.log("Product deleted successfully");
        alert("The product was successfully deleted");
        window.location.reload();
      } else {
        console.log("Failed to delete product");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <li>
      <div className={classes.itemWrapper}>
        <div className={classes.itemDetails}>
          <div className={classes.itemImg}>
            <img src={item.imageLink} alt="item" />
          </div>
          <div className={classes.itemInfo}>
            <h4>{item.title}</h4>
            <p>Price: ${item.price}</p>
          </div>
        </div>
        <div className={classes.itemActions}>
          <div className={classes.update}>
            <MdEdit onClick={() => console.log("updated")} />
          </div>
          <div className={classes.delete}>
            <MdDelete onClick={() => handleDelete(item._id)} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default ItemList;
