import React from "react";
import classes from "./CartItem.module.css";

const CartItem = ({ item, onRemove, onAdd, viewMyCart }) => {
  return (
    <li className={classes["cart-item"]}>
      <div className={classes["item-dp"]}>
        <img src={item.imageLink} alt="productPic" />
        {item.amount > 1 && <span>x{item.amount}</span>}
      </div>
      <h4>{viewMyCart ? item.title : `${item.title.slice(0, 38)}`}</h4>
      <div className={classes.cartCounter}>
        <button onClick={onRemove}>-</button>
        <input type="text" inputmode="numeric" value={item.amount} />
        <button onClick={onAdd}>+</button>
      </div>
      <h5>= ${(item.price * item.amount).toFixed(2)}</h5>
    </li>
  );
};

export default CartItem;
