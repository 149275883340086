import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../shared/Modal";
import classes from "./QuickView.module.css";
import ProductRating from "./ProductRating";
import CartContext from "../../store/cart-context";

const QuickView = ({ onHide, product }) => {
  const [counter, setCounter] = useState(1);
  const [isAdded, setIsAdded] = useState(false);
  const cartCtx = useContext(CartContext);

  const handleIncrement = () => {
    setCounter((prev) => prev + 1);
  };
  const handleDecrement = () => {
    if (counter > 1) {
      setCounter((prev) => prev - 1);
    }
  };
  const title =
    product.title.length > 37
      ? `${product.title.slice(0, 37)}...`
      : product.title;

  const mdPrice = `$${product.price.toFixed(2)} USD`;
  const mdOldPrice = `$${product.oldPrice?.toFixed(2)}`;
  const discount =
    ((product.oldPrice - product.price) / product.oldPrice) * 100;
  const mdDiscount = `${discount.toFixed(0)}% OFF`;

  const addItemHandler = () => {
    cartCtx.addItem({
      id: product._id,
      title: product.title,
      imageLink: product.imageLink,
      price: product.price,
      amount: counter,
    });
    setIsAdded(true);
    if (isAdded) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <Modal onHideCart={onHide}>
      <div className={classes.quickView}>
        {/* <div className={classes.closeIcon} onClick={onHide}>
          <span className={`${classes.line} ${classes.lineOne}`}></span>
          <span className={`${classes.line} ${classes.lineTwo}`}></span>
        </div> */}
        <div className={classes.display}>
          <div className={classes.itemImg}>
            <img src={product.imageLink} alt="product show" />
          </div>

          <div className={classes.content}>
            <h2>{title}</h2>
            <ProductRating rating={product.rating} />
            <div className={classes.price}>
              {mdPrice}
              {product.oldPrice && <span>{mdOldPrice}</span>}
              {product.oldPrice && (
                <div className={classes.discount}>
                  <span></span>
                  <p>{mdDiscount}</p>
                </div>
              )}
            </div>
            <div className={classes.counter}>
              <button onClick={handleDecrement}>-</button>
              <span>{counter}</span>
              <button onClick={handleIncrement}>+</button>
            </div>
            <Link
              to={isAdded ? "/cart" : "#"}
              className={classes.cartBtn}
              onClick={addItemHandler}
            >
              {isAdded ? "CHECKOUT" : "ADD TO CART"}
            </Link>
            <div className={classes.closeBtn} onClick={onHide}>
              CLOSE
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuickView;
