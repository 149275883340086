import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import Carousel from "../components/homeCarousel/Carousel";
import FeaturedCategories from "../components/homeCategories/FeaturedCategories";
import SaleSection from "../components/homeSaleSection/SaleSection";
import HitProducts from "../components/homeHitProducts/HitProducts";
import LoadingSpinner from "../components/shared/LoadingSpinner";

const Home = () => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await fetch("https://backend.buypits.com/products");
        const data = await res.json();
        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <>
      <Helmet>
        <title>BuyPits | Let's Start the Journey</title>
        <meta
          name="description"
          content="Discover the future of shopping at BuyPits.com! Explore unbeatable deals on a vast selection of must-have items."
        />
      </Helmet>

      <Carousel />
      <FeaturedCategories />
      {products ? <HitProducts products={products} /> : <LoadingSpinner />}
      <SaleSection />
    </>
  );
};

export default Home;
