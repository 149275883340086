import React, { useContext } from "react";
import { MdOutlineShoppingCart } from "react-icons/md";
import { MdFullscreen } from "react-icons/md";

import cartContext from "../../store/cart-context";

import classes from "./ProductActions.module.css";

const ProductActions = ({ isHovered, product, onChangeActions, onShow }) => {
  const cartCtx = useContext(cartContext);

  const handleAddToCart = (e) => {
    e.preventDefault();
    const item = {
      id: product._id,
      title: product.title,
      imageLink: product.imageLink,
      price: product.price,
    };
    cartCtx.addItem({ ...item, amount: 1 });
  };
  const mouseEnterHandler = () => {
    onChangeActions(true);
  };
  const mouseLeaveHandler = () => {
    onChangeActions(false);
  };
  const handleQuickView = (e) => {
    e.preventDefault();
    onShow();
  };

  return (
    <div
      className={`${classes.productActions} ${isHovered ? classes.show : ""}`}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onClick={(e) => e.preventDefault()}
    >
      <div className={classes.icon} onClick={handleAddToCart}>
        <MdOutlineShoppingCart className={classes.productCart} />
        <span className={classes.hint}>Add to Cart</span>
      </div>
      <div className={classes.icon} onClick={handleQuickView}>
        <MdFullscreen className={classes.productView} />
        <span className={classes.hint}>Quick View</span>
      </div>
    </div>
  );
};

export default ProductActions;
