import React, { useState, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Header from "./components/header/Header";
import Banner from "./components/banner/Banner";
import Footer from "./components/footer/Footer";
import Cart from "./components/cart/Cart";
import CartProvider from "./store/CartProvider";
import LoadingSpinner from "./components/shared/LoadingSpinner";

import Home from "./pages/Home";

import Success from "./components/success/Success";
// import Checkout from "./pages/Checkout";
// import Products from "./pages/Products";
// import Product from "./pages/Product";
// import Collections from "./pages/Collections";
// import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
// import ReturnPolicy from "./pages/return/ReturnPolicy";
// import ShippingInfo from "./pages/shipping/ShippingInfo";
// import Faqs from "./pages/faqs/Faqs";
// import Contact from "./pages/contact/Contact";
// import ProductsByCollection from "./pages/ProductsByCollection";

// import Sale from "./pages/discount/Sale";
// import MyCart from "./pages/MyCart";
import ProductsManagement from "./pages/ProductsManagement";

const Products = React.lazy(() => import("./pages/Products"));
const Product = React.lazy(() => import("./pages/Product"));
const Collections = React.lazy(() => import("./pages/Collections"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacy/PrivacyPolicy"));
const ReturnPolicy = React.lazy(() => import("./pages/return/ReturnPolicy"));
const ShippingInfo = React.lazy(() => import("./pages/shipping/ShippingInfo"));
const Faqs = React.lazy(() => import("./pages/faqs/Faqs"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const ProductsByCollection = React.lazy(() =>
  import("./pages/ProductsByCollection")
);
const Sale = React.lazy(() => import("./pages/discount/Sale"));
const MyCart = React.lazy(() => import("./pages/MyCart"));
const Checkout = React.lazy(() => import("./pages/Checkout"));

function App() {
  const [cartIsShown, setCartIsShown] = useState(false);
  const showCartHandler = () => {
    setCartIsShown(true);
  };
  const hideCartHandler = () => {
    setCartIsShown(false);
  };
  return (
    <BrowserRouter>
      <HelmetProvider>
        <CartProvider>
          {cartIsShown && <Cart onHideCart={hideCartHandler} />}
          <Header onShowCart={showCartHandler} />
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:pid" element={<Product />} />
              <Route path="/collections" element={<Collections />} />
              <Route
                path="/collections/:name"
                element={<ProductsByCollection />}
              />

              <Route path="/cart" element={<MyCart />} />
              <Route path="/sale" element={<Sale />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/return" element={<ReturnPolicy />} />
              <Route path="/shippingInfo" element={<ShippingInfo />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/success" element={<Success />} />

              <Route path="/manage-products" element={<ProductsManagement />} />

              <Route path="*" element={<Home />} />
            </Routes>
          </Suspense>
          <Banner />
          <Footer />
        </CartProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
