import React, { useState } from "react";

import ProductRating from "./ProductRating";
import classes from "./Product.module.css";
import ProductActions from "./ProductActions";
import { Link } from "react-router-dom";
import QuickView from "./QuickView";

const Product = ({ product }) => {
  const [quickView, setQuickview] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCartActions, setIsCartAction] = useState(false);
  const mouseEnterHandler = () => {
    setIsHovered(true);
  };
  const mouseLeaveHandler = () => {
    setIsHovered(false);
  };

  const getVal = (a) => {
    setIsCartAction(a);
  };

  const discount = Math.floor(
    ((product.oldPrice - product.price) / product.oldPrice) * 100
  );
  const scrollToTop = () => {
    if (!isCartActions) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const closeQuickViewHandler = () => {
    setQuickview(false);
  };
  const showQuickViewHandler = () => {
    setQuickview(true);
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className={`${classes.product} ${classes[product.category]}`}
    >
      {quickView && (
        <QuickView product={product} onHide={closeQuickViewHandler} />
      )}
      <Link to={`/products/${product._id}`} onClick={scrollToTop}>
        <div className={classes.productImg}>
          <img
            src={
              isHovered && product.hoverLink
                ? product.hoverLink
                : product.imageLink
            }
            alt={`Product ${product._id}`}
          />

          {product.oldPrice && (
            <div className={classes.discount}>{discount}&#37;</div>
          )}

          <ProductActions
            isHovered={isHovered}
            product={product}
            onChangeActions={getVal}
            onShow={showQuickViewHandler}
          />
        </div>

        <div className={classes.productInfo}>
          <h3>
            {product.title.length > 36
              ? product.title.slice(0, 36)
              : product.title}
          </h3>
          <p>{product.category}</p>
          <ProductRating rating={product.rating} />
          <h4>
            Price ${product.price}{" "}
            {product.oldPrice && <span>{product.oldPrice.toFixed(2)}</span>}
          </h4>
        </div>
      </Link>
    </div>
  );
};

export default Product;
