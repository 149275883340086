import React, { useEffect } from "react";
import classes from "./Success.module.css";

const Success = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // Refresh the page
      window.location.reload();
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clear the timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={classes.centerDiv}>
      <h3>Thank you for your order!</h3>
      <p>
        It is currently being processed and you will receive a confirmation
        email shortly. We apologize for any inconvenience, but our payment
        processing services are currently experiencing technical difficulties.
      </p>
    </div>
  );
};

export default Success;
