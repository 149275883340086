import React, { useState } from "react";
import ManageActions from "./ManageActions";
import ActionWindow from "./ActionWindow";

import classes from "./ManageOutlet.module.css";

const ManageOutlet = () => {
  const [toggleState, setToggleState] = useState(1);
  return (
    <div className={classes.manageOutlet}>
      <h2>Product Management</h2>
      <div className={classes.outletWrapper}>
        <ManageActions
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
        <ActionWindow toggleState={toggleState} />
      </div>
    </div>
  );
};

export default ManageOutlet;
