import React from "react";

import classes from "./ManageActions.module.css";

const ManageActions = ({ toggleState, setToggleState }) => {
  return (
    <div className={classes.manageActions}>
      <div
        className={
          toggleState === 1
            ? `${classes.tab} ${classes.activeTab}`
            : classes.tab
        }
        onClick={() => setToggleState(1)}
      >
        All Items
      </div>
      <div
        className={
          toggleState === 2
            ? `${classes.tab} ${classes.activeTab}`
            : classes.tab
        }
        onClick={() => setToggleState(2)}
      >
        Add New Item
      </div>
      <div
        className={
          toggleState === 3
            ? `${classes.tab} ${classes.activeTab}`
            : classes.tab
        }
        onClick={() => setToggleState(3)}
      >
        Show Orders
      </div>
    </div>
  );
};

export default ManageActions;
