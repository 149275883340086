import React, { useEffect, useState } from "react";

import classes from "./ActionWindow.module.css";
import UpdateAndDelete from "./UpdateAndDelete";
import LoadingSpinner from "../shared/LoadingSpinner";
import CreateProduct from "./CreateProduct";
import ShowOrders from "./ShowOrders";

const ActionWindow = ({ toggleState }) => {
  const [products, setProducts] = useState(null);
  const [orders, setOrders] = useState(null);
  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await fetch("https://backend.buypits.com/products");
        const data = await res.json();
        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchOrders = async () => {
      try {
        const res = await fetch("https://backend.buypits.com/order");
        const data = await res.json();
        setOrders(data.orders);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
    getProducts();
  }, []);

  return (
    <div className={classes.windows}>
      <div
        className={
          toggleState === 1
            ? `${classes.window} ${classes.activeWindow}`
            : classes.window
        }
      >
        {products ? (
          <UpdateAndDelete products={products} />
        ) : (
          <LoadingSpinner />
        )}
      </div>

      <div
        className={
          toggleState === 2
            ? `${classes.window} ${classes.activeWindow}`
            : classes.window
        }
      >
        <CreateProduct />
      </div>

      <div
        className={
          toggleState === 3
            ? `${classes.window} ${classes.activeWindow}`
            : classes.window
        }
      >
        {orders ? <ShowOrders orders={orders} /> : <LoadingSpinner />}
      </div>
    </div>
  );
};

export default ActionWindow;
